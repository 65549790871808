import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

const contentsList = [
  'フードデリバリー市場について',
  'フードデリバリーマネージャー（FDM）とは',
  '５つの特長',
  '活用イメージ',
  'さらに詳しく知りたい方へ',
];

type DownloadHeadingProps = {
  className?: string;
}

const DownloadHeading: React.VFC<DownloadHeadingProps> = ({className}) => (
  <div className={classNames('px-2.5 md:px-0', className)}>
    <p className="text-sm tracking-wide leading-loose">
      フードデリバリーマネージャーにご興味をお持ちくださり、誠にありがとうございます。<br />
      この資料では、機能や導入後の効果などについてわかりやすくご説明いたします。
    </p>
    <div className="relative hidden md:block mt-10 pb-16">
      <div className="w-62.5 shadow-sm">
        <StaticImage src="../../images/document/img_doc01.png" alt=""/>
      </div>
      <div className="absolute right-0 bottom-0 w-62.5 shadow-sm">
        <StaticImage src="../../images/document/img_doc02.png" alt=""/>
      </div>
    </div>
    <div className="flex md:block mt-7.5 md:mt-10 pt-3 pl-4 pb-4 pr-3 md:py-6 md:px-7.5 border-4 border-white rounded-lg">
      <div className="md:hidden">
        <div className="mt-2.5 w-25 shadow-sm">
          <StaticImage src="../../images/document/img_doc01.png" alt=""/>
        </div>
        <div className="mt-2.5 w-25 shadow-sm">
          <StaticImage src="../../images/document/img_doc02.png" alt=""/>
        </div>
      </div>
      <nav className="flex-1 ml-3.75">
        <h2 className="text-sm font-medium tracking-wide leading-loose">目次</h2>
        <ul className="md:mt-2.5 space-y-2 md:space-y-0">
          {contentsList.map(item => (
            <li key={item} className="relative pl-4 md:pl-6 text-xs md:text-sm tracking-wide leading-normal md:leading-loose"><span className="absolute left-0 text-primary">—</span>{item}</li>
          ))}
        </ul>
      </nav>
    </div>
  </div>
);

export default DownloadHeading;