import * as React from 'react';
import Layout from '../components/common/Layout';
import MainContents from '../components/common/MainContents';
import PageHeading from '../components/ui/Pageheading';
import DownloadHeading from '../components/download/DownloadHeading';
import DownloadForm from '../components/download/DownloadForm';

const DownloadPage = () => (
    <Layout pageTitle="ご紹介資料 ダウンロード | FDM フードデリバリーマネージャー">
      <MainContents>
        <PageHeading>ご紹介資料 ダウンロード</PageHeading>
        <div className="mt-7.5 md:mt-20 md:flex">
          <DownloadHeading className="flex-1"/>
          <div className="mt-7.5 md:mt-0 md:ml-15 md:w-110">
            <DownloadForm />
            <p className="mt-5 text-xs leading-relaxed">フォーム送信完了後、メールにて資料ダウンロードのご案内をいたします</p>
          </div>
        </div>
      </MainContents>
    </Layout>
  );

export default DownloadPage;
