import classNames from 'classnames';
import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { DownloadFormData } from '../../types/DownloadFormData';
import { ContactFormData } from '../../types/ContactFormData';

type SelectPropsType = {
  className?: string;
  name: Path<ContactFormData | DownloadFormData>;
  register: UseFormRegister<ContactFormData | DownloadFormData>;
  option: string[];
  required: boolean;
}

export const Select: React.VFC<SelectPropsType> = ({className, name, register, option, required}) => (
  <div className={classNames('', className)}>
    <select
      id="location"
      className="block w-full pt-3.75 pl-3.75 pr-10 py-3.75 border border-gray-lightest focus:outline-none sm:text-sm rounded-sm"
      defaultValue="選択してください。"
      {...register(name, { required })}
    >
      <option value="選択してください">選択してください</option>
      {option.map((item => (
        <option key={item}>{item}</option>
      )))}
    </select>
  </div>
);
