import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { navigate } from 'gatsby-link';
import { FormButton } from '../ui/FormButton';
import { Input } from '../ui/Input';
import { Required } from '../ui/Required';
import { Select } from '../ui/Select';
import { PrivacyLink } from '../ui/PrivacyLink';
import ErrorText from '../ui/ErrorText';
import { DownloadFormData } from '../../types/DownloadFormData';

type DownloadFormProps = {
  className?: string;
};

const DownloadForm: React.VFC<DownloadFormProps> = ({ className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DownloadFormData>();

  const motivationOption: string[] = [
    'Instagram',
    'メール',
    'WEB広告',
    '検索エンジン',
    'その他',
  ];
  const onSubmit: SubmitHandler<DownloadFormData> = async data => {
    const server =
      'https://wsln0g8d5m.execute-api.ap-northeast-1.amazonaws.com/prod/';
    const param = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(server, JSON.stringify(data), param)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    navigate('/download/thanks');
  };

  return (
    <div
      className={classNames(
        'py-10 px-5 md:p-10 bg-white rounded-lg shadow-sm',
        className
      )}
    >
      <p className="text-sm tracking-wide leading-loose text-center">
        必要項目 <span className="text-warn">※</span>{' '}
        をご記入の上、お進みください
      </p>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <input type="hidden" name="oid" value="00D5h0000011qo1" />
        <input
          type="hidden"
          name="retURL"
          value="https://fooddeliverymanager.com/thanks.html"
        />
        <input type="hidden" name="debug" value="1" />
        <input type="hidden" name="debugEmail" value="torii@lisatech.jp" />

        <dl className="mt-8">
          <dt className="mt-5 text-sm">
            お名前
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              name="firstName"
              placeholder="氏名"
              register={register}
              required
              error={!!errors.firstName?.type}
            />
            {errors.firstName?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            店舗名
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              name="shopName"
              placeholder="例）フードデリバリー品川店"
              register={register}
              required
              error={!!errors.shopName?.type}
            />
            {errors.shopName?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">会社名</dt>
          <dd className="mt-3">
            <Input
              name="company"
              placeholder="例）株式会社フードデリバリー"
              register={register}
              required={false}
              error={!!errors.company?.type}
            />
          </dd>
          <dt className="mt-5 text-sm">
            メールアドレス
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              type="email"
              name="email"
              placeholder="例）example@delivery.co.jp"
              register={register}
              required
              error={!!errors.email?.type}
            />
            {errors.email?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            お電話番号
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              type="tel"
              name="phone"
              placeholder="例）01-2345-6789"
              register={register}
              required
              error={!!errors.phone?.type}
            />
            {errors.phone?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            何を見てこちらのサイトを知りましたか
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Select
              name="motivation"
              option={motivationOption}
              register={register}
              required
            />
            {errors.motivation?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
        </dl>
        <PrivacyLink className="mt-7.5" />
        <div className="mt-7.5 md:w-75 mx-auto max-w-xxs">
          <FormButton
            color="primary"
            onClick={handleSubmit(onSubmit)}
            className="w-full"
          >
            ダウンロードする（無料）
          </FormButton>
        </div>
      </form>
    </div>
  );
};

export default DownloadForm;
